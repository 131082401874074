import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { RoomsApiService } from '../services/rooms-api.service';

@Component({
  selector: 'app-rooms-list',
  templateUrl: './rooms-list.component.html',
  styleUrls: ['./rooms-list.component.css']
})
export class RoomsListComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  constructor(private breakpointObserver: BreakpointObserver, private dataApi: RoomsApiService) { }
  public rooms = [];

  ngOnInit() {
    this.getRooms();
  }

  getRooms() {
    this.dataApi.getAllRooms().subscribe(rooms => {
      console.log('ROOMS', rooms);
      this.rooms = rooms;
    });
  }

}
