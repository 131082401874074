import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RoomsApiService } from '../services/rooms-api.service';
import { RoomInterface } from '../models/room';

@Component({
  selector: 'app-room-modal',
  templateUrl: './room-modal.component.html',
  styleUrls: ['./room-modal.component.css']
})
export class RoomModalComponent {

  constructor(
    public dialogRef: MatDialogRef<RoomModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RoomInterface,
    public dataApi: RoomsApiService
  ) {}

  onNoClick(): void {
    this.dataApi.unSelectedRoom();
    this.dialogRef.close();
  }

  onSaveRoom(): void {
    if (this.dataApi.selectedRoom.id) {
      this.dataApi.selectedRoom.userUid = this.data.userUid;
      this.dataApi.updateRoom(this.dataApi.selectedRoom); // Update Room
    } else {
      this.dataApi.selectedRoom.userUid = this.data.userUid;
      this.dataApi.addRoom(this.dataApi.selectedRoom); // New Room
    }
    this.dataApi.unSelectedRoom();
  }

  onDeleteRoom(): void {
    const confirmacion = confirm('¿Estás seguro?');
    if (confirmacion) {
      this.dataApi.deleteRoom(this.dataApi.selectedRoom.id);
    }
  }
}
