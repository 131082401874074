import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  public isLogged = false;
  public profileImg: string;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public authService: AuthService,
    private router: Router) {}

  ngOnInit(): void {
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.authService.isAuth().subscribe( auth => {
      if (auth) {
        this.isLogged = true;
        console.log('User logged: ', auth.displayName);
        this.authService.selectedUser.id = auth.uid;
        this.profileImg = auth.photoURL;
        this.authService.isUserAdmin(this.authService.selectedUser.id).subscribe(userRoles => {
          if (userRoles.roles) {
            this.authService.selectedUser.roles.admin = userRoles.roles.admin;
          }
        });
      } else {
        console.log('User not logged');
        this.isLogged = false;
      }
    });
  }

  onLogout() {
    this.authService.selectedUser.roles.admin = false;
    this.authService.logoutUser();
    this.router.navigate(['user/login']);
  }
}
