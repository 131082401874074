import { Component, OnInit } from '@angular/core';
import { PicturesApiService } from '../services/pictures-api.service';

@Component({
  selector: 'app-pictures-list',
  templateUrl: './pictures-list.component.html',
  styleUrls: ['./pictures-list.component.css']
})
export class PicturesListComponent implements OnInit {
  constructor(private dataApi: PicturesApiService) { }
  public pictures = [];

  ngOnInit() {
    this.getPictures();
  }

  getPictures() {
    this.dataApi.getAllPictures().subscribe( pictures => {
      console.log('Pictures', pictures);
      this.pictures = pictures;
    });
  }

}
