import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { SpacesComponent } from './spaces.component';
import { SpacesAdminComponent } from './items/admin/spaces-admin.component';
import { AdminGuard } from 'src/app/UI/guard/admin.guard';
import { PicturesAdminComponent } from './pictures/admin/pictures-admin.component';

export const routes: Routes = [
  { path: 'spaces', component: SpacesComponent },
  { path: 'admin/spaces', component: SpacesAdminComponent, canActivate: [AdminGuard] },
  { path: 'admin/spacespictures', component: PicturesAdminComponent, canActivate: [AdminGuard] },
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
