import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/UI/material/material.module';

import { routing } from './events.routing';
import { EventsComponent } from './events.component';
import { EventsAdminComponent } from './admin/events-admin.component';
import { EventModalComponent } from './modal/event-modal.component';
import { EventDetailsComponent } from './details/event-details.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    routing
  ],
  declarations: [
    EventDetailsComponent,
    EventsComponent,
    EventsAdminComponent,
    EventModalComponent
  ],
  exports: [
    EventDetailsComponent,
    EventsComponent,
    EventsAdminComponent,
    EventModalComponent
  ],
  entryComponents: [
    EventModalComponent,
  ]
})
export class EventsModule { }
