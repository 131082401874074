import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cares',
  templateUrl: './cares.component.html',
  styleUrls: ['./cares.component.css']
})
export class CaresComponent implements OnInit {
  public index = 0;

  constructor() { }

  ngOnInit() { }

}
