import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { CaresComponent } from './cares.component';
import { CaresAdminComponent } from './services/admin/cares-admin.component';
import { PicturesAdminComponent } from './pictures/admin/pictures-admin.component';
import { AdminGuard } from 'src/app/UI/guard/admin.guard';

export const routes: Routes = [
  { path: 'cares', component: CaresComponent },
  { path: 'admin/cares', component: CaresAdminComponent, canActivate: [AdminGuard] },
  { path: 'admin/carespictures', component: PicturesAdminComponent, canActivate: [AdminGuard] },
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
