import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  constructor(private breakpointObserver: BreakpointObserver) {}
  muted = true;
  showFiller = false;

  historyPictures = [
    {
      id: 1,
      title: 'A natural view',
      img: 'assets/img/home/historia-3.jpg'
    },
    {
      id: 2,
      title: 'Newspaper',
      img: 'assets/img/home/historia-2.jpg'
    },
    {
      id: 3,
      title: 'Favourite pizza',
      img: 'assets/img/home/historia-1.jpg'
    },
    {
      id: 4,
      title: 'Abstract design',
      img: 'assets/img/home/historia-5.jpg'
    },
    {
      id: 5,
      title: 'Tech',
      img: 'assets/img/home/historia-6.jpg'
    }
  ];

  homePictures = [
    {
      id: 1,
      title: 'A natural view',
      img: 'assets/img/services/servicios-14.jpg'
    },
    {
      id: 2,
      title: 'Newspaper',
      img: 'assets/img/home/home-10.jpg'
    },
    {
      id: 3,
      title: 'Favourite pizza',
      img: 'assets/img/home/home-1.jpg'
    },
    {
      id: 4,
      title: 'Abstract design',
      img: 'assets/img/home/home-13.jpg'
    },
    {
      id: 5,
      title: 'Tech',
      img: 'assets/img/home/home-3.jpg'
    },
    {
      id: 6,
      title: 'Nightlife',
      img: 'assets/img/home/home-5.jpg'
    },
    {
      id: 7,
      title: 'Nightlife',
      img: 'assets/img/home/home-6.jpg'
    },
    {
      id: 8,
      title: 'Tech',
      img: 'assets/img/home/home-8.jpg'
    },
    {
      id: 9,
      title: 'Nightlife',
      img: 'assets/img/home/home-9.jpg'
    },
    {
      id: 10,
      title: 'Nightlife',
      img: 'assets/img/home/home-7.jpg'
    },
    {
      id: 11,
      title: 'Nightlife',
      img: 'assets/img/home/home-12.jpg'
    },
    {
      id: 12,
      title: 'Tech',
      img: 'assets/img/home/home-11.jpg'
    },
    {
      id: 13,
      title: 'Nightlife',
      img: 'assets/img/home/home-4.jpg'
    },
    {
      id: 14,
      title: 'Nightlife',
      img: 'assets/img/home/home-2.jpg'
    },
    {
      id: 15,
      title: 'Nightlife',
      img: 'assets/img/services/servicios-15.jpg'
    }
  ];


  volumeOff() {
    this.muted = !this.muted;
  }

}
