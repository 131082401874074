import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { CaresApiService } from '../services/cares-api.service';

@Component({
  selector: 'app-cares-list',
  templateUrl: './cares-list.component.html',
  styleUrls: ['./cares-list.component.css']
})
export class CaresListComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  constructor(private breakpointObserver: BreakpointObserver, private dataApi: CaresApiService) { }
  public includedCares = [];
  public notIncludedCares = [];

  ngOnInit() {
    this.getIncludedCares();
    this.getNotIncludedCares();
  }

  getIncludedCares() {
    this.dataApi.getFilteredCares('type', 'Servicios incluidos').subscribe(cares => {
      console.log('INCLUDES CARES', cares);
      this.includedCares = cares;
    });
  }

  getNotIncludedCares() {
    this.dataApi.getFilteredCares('type', 'Servicios de pago').subscribe(cares => {
      console.log('INCLUDES CARES', cares);
      this.notIncludedCares = cares;
    });
  }

}
