import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { RoomInterface } from '../models/room';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoomsApiService {
  constructor(private afs: AngularFirestore, private storage: AngularFireStorage) {}

  private booksCollection: AngularFirestoreCollection<RoomInterface>;
  private rooms: Observable<RoomInterface[]>;
  private bookDoc: AngularFirestoreDocument<RoomInterface>;
  private room: Observable<RoomInterface>;
  public selectedRoom: RoomInterface = {
    id: null
  };

  unSelectedRoom() {
    this.selectedRoom = {};
  }

  getAllRooms() {
    this.booksCollection = this.afs.collection<RoomInterface>('rooms', ref => ref
    .orderBy('order'));
    return this.rooms = this.booksCollection.snapshotChanges()
    .pipe(map( changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as RoomInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }
  getFilteredRooms(field: string, filter: string) {
    this.booksCollection = this.afs.collection<RoomInterface>('rooms', ref => ref
    .where(field, '==', filter)
    .orderBy('order'));
    return this.rooms = this.booksCollection.snapshotChanges()
    .pipe(map( changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as RoomInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  addRoom(room: RoomInterface): void {
    this.booksCollection.add(room);
  }
  updateRoom(room: RoomInterface): void {
    const idRoom = room.id;
    this.bookDoc = this.afs.doc<RoomInterface>(`rooms/${idRoom}`);
    this.bookDoc.update(room);
  }
  deleteRoom(idRoom: string): void {
    this.bookDoc = this.afs.doc<RoomInterface>(`rooms/${idRoom}`);
    this.bookDoc.delete();
  }
}
