import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { AuthService } from 'src/app/UI/services/auth.service';
import { UsersApiService } from '../services/users-api.service';
import { UserInterface } from '../models/user';
import { UserModalComponent } from '../modal/user-modal.component';


@Component({
  selector: 'app-users-admin',
  templateUrl: './users-admin.component.html',
  styleUrls: ['./users-admin.component.css'],
})
export class UsersAdminComponent implements OnInit {
  displayedColumns = ['firstname', 'lastname', 'nights', 'phone', 'email', 'edit'];
  columnsToDisplay = ['firstname', 'lastname', 'nights', 'phone', 'email'];
  dataSource: MatTableDataSource<UserInterface>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public userUid: string = null;
  public isAdmin: any = null;
  public isEditor: any = null;

  constructor(
    private dataApi: UsersApiService, public dialog: MatDialog, public authService: AuthService) { }

  ngOnInit() {
    this.getListUsers();
    this.getCurrentUser();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getCurrentUser() {
    this.authService.isAuth().subscribe( auth => {
      if (auth) {
        this.authService.isUserAdmin(this.authService.selectedUser.id).subscribe(userRoles => {
          if (userRoles.roles) {
            this.authService.selectedUser.roles.admin = userRoles.roles.admin;
          }
        });
      }
    });
  }

  getListUsers() {
    this.dataApi.getAllUsers().subscribe( users => {
      this.dataSource = new MatTableDataSource(users);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  onDeleteUser(idUser: string): void {
    const confirmacion = confirm('Are you sure?');
    if (confirmacion) {
      this.dataApi.deleteUser(idUser);
    }
  }

  onPreUpload(user: UserInterface) {
    console.log('User Upload', user);
    this.dataApi.selectedUser = Object.assign({}, user);
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(UserModalComponent, {
      width: '500px',
      data: {userUid: this.userUid}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.userUid = result.userUid;
        console.log('data', result);
      }
    });
  }

}
