import { Component, Inject, ElementRef, ViewChild, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PicturesApiService } from '../services/pictures-api.service';
import { PictureInterface } from '../models/picture';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-picture-modal',
  templateUrl: './picture-modal.component.html',
  styleUrls: ['./picture-modal.component.css']
})
export class PictureModalComponent implements OnInit {
  uploadPercent: Observable<number>;
  urlImg: Observable<string>;
  @ViewChild('imgUpload') inputImgUpload: ElementRef;
  public spaces = [];

  constructor(
    public dialogRef: MatDialogRef<PictureModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PictureInterface,
    public dataApi: PicturesApiService,
    private storage: AngularFireStorage
  ) {}

  ngOnInit() {
    this.getAllSpaces();
  }

  getAllSpaces() {
    this.dataApi.getAllSpaces().subscribe(spaces => {
      console.log('SPACES', spaces);
      this.spaces = spaces;
    });
  }

  onNoClick(): void {
    this.dataApi.unSelectedPicture();
    this.dialogRef.close();
  }

  onUpload(e): void {
    let id: string;
    if (this.dataApi.selectedPicture.imgId) {
      id = this.dataApi.selectedPicture.imgId;
    } else {
      id = Math.random()
        .toString(36)
        .substring(2);
      this.dataApi.selectedPicture.imgId = id;
    }
    const file = e.target.files[0];
    const filePath = `spacespictures/picture_${id}.jpg`;
    const ref = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    task
      .snapshotChanges()
      .pipe(finalize(() => (this.urlImg = ref.getDownloadURL())))
      .subscribe();
  }

  onSavePicture(): void {
    if (this.dataApi.selectedPicture.id) {
      this.dataApi.selectedPicture.userUid = this.data.userUid;
      this.dataApi.selectedPicture.urlImg = this.inputImgUpload.nativeElement.value;
      this.dataApi.updatePicture(this.dataApi.selectedPicture); // Update Picture
    } else {
      this.dataApi.selectedPicture.userUid = this.data.userUid;
      this.dataApi.selectedPicture.urlImg = this.inputImgUpload.nativeElement.value;
      this.dataApi.addPicture(this.dataApi.selectedPicture); // New Picture
    }
    this.dataApi.unSelectedPicture();
  }

  onDeletePicture(): void {
    const confirmacion = confirm('¿Estás seguro?');
    if (confirmacion) {
      this.dataApi.onUploadDelete(this.dataApi.selectedPicture.imgId);
      this.dataApi.deletePicture(this.dataApi.selectedPicture.id);
    }
  }
}
