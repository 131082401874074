import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

import { AuthService } from 'src/app/UI/services/auth.service';

import { PicturesApiService } from '../services/pictures-api.service';
import { PictureInterface } from '../models/picture';
import { PictureModalComponent } from '../modal/picture-modal.component';


@Component({
  selector: 'app-pictures-admin',
  templateUrl: './pictures-admin.component.html',
  styleUrls: ['./pictures-admin.component.css'],
})
export class PicturesAdminComponent implements OnInit {
  displayedColumns = ['name', 'room', 'view', 'edit'];
  columnsToDisplay = ['name', 'room'];
  dataSource: MatTableDataSource<PictureInterface>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public userUid: string = null;
  public isAdmin: any = null;
  public isEditor: any = null;

  constructor(private dataApi: PicturesApiService, public dialog: MatDialog, public authService: AuthService) { }

  ngOnInit() {
    this.getListPictures();
    this.getCurrentUser();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getCurrentUser() {
    this.authService.isAuth().subscribe( auth => {
      if (auth) {
        this.authService.isUserAdmin(this.authService.selectedUser.id).subscribe(userRoles => {
          if (userRoles.roles) {
            this.authService.selectedUser.roles.admin = userRoles.roles.admin;
          }
        });
      }
    });
  }

  getListPictures() {
    this.dataApi.getAllPictures().subscribe( pictures => {
      this.dataSource = new MatTableDataSource(pictures);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  onPreUpload(picture: PictureInterface) {
    console.log('Picture Upload', picture);
    this.dataApi.selectedPicture = Object.assign({}, picture);
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(PictureModalComponent, {
      width: '500px',
      data: {userUid: this.userUid}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.userUid = result.userUid;
        console.log('data', result);
      }
    });
  }

}
