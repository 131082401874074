import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { CareInterface } from '../models/care';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CaresApiService {
  constructor(private afs: AngularFirestore, private storage: AngularFireStorage) {}

  private booksCollection: AngularFirestoreCollection<CareInterface>;
  private cares: Observable<CareInterface[]>;
  private bookDoc: AngularFirestoreDocument<CareInterface>;
  private care: Observable<CareInterface>;
  public selectedCare: CareInterface = {
    id: null
  };

  unSelectedCare() {
    this.selectedCare = {};
  }

  getAllCares() {
    this.booksCollection = this.afs.collection<CareInterface>('cares', ref => ref
    .orderBy('order'));
    return this.cares = this.booksCollection.snapshotChanges()
    .pipe(map( changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as CareInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }
  getFilteredCares(field: string, filter: string) {
    this.booksCollection = this.afs.collection<CareInterface>('cares', ref => ref
    .where(field, '==', filter)
    .orderBy('order'));
    return this.cares = this.booksCollection.snapshotChanges()
    .pipe(map( changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as CareInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  addCare(care: CareInterface): void {
    this.booksCollection.add(care);
  }
  updateCare(care: CareInterface): void {
    const idCare = care.id;
    this.bookDoc = this.afs.doc<CareInterface>(`cares/${idCare}`);
    this.bookDoc.update(care);
  }
  deleteCare(idCare: string): void {
    this.bookDoc = this.afs.doc<CareInterface>(`cares/${idCare}`);
    this.bookDoc.delete();
  }
}
