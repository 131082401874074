import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../services/auth.service';
import { UserInterface } from '../../models/user';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(private authService: AuthService) {}
  user: UserInterface = {
    name: '',
    email: '',
    photoUrl: '',
    emailVerified: false,
    roles: {},
  };
  public providerId = '';

  ngOnInit() {
    this.authService.isAuth().subscribe(user => {
      if (user) {
        this.user.name = user.displayName;
        this.user.email = user.email;
        this.user.photoUrl = user.photoURL;
        this.user.emailVerified = user.emailVerified;
        this.providerId = user.providerData[0].providerId;
      }
    });
  }

  onChangePassword(): void {
    const confirmacion = confirm('¿Seguro que quieres cambiar la contraseña?');
    if (confirmacion) {
      console.log('Change password', this.user.email);
      this.authService.sendPasswordResetEmail(this.user.email);
    }
  }
}
