import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { LayoutModule } from '@angular/cdk/layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// Routing
import { routing } from './app-routing.module';
// Firebase
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
// Material
import { MaterialModule } from './UI/material/material.module';

// UI Components
import { NavigationComponent } from './UI/navigation/navigation.component';
import { LoginComponent } from './UI/users/login/login.component';
import { RegisterComponent } from './UI/users/register/register.component';
import { ProfileComponent } from './UI/users/profile/profile.component';
import { UsersAdminComponent } from './UI/users/admin/users-admin.component';
import { UserModalComponent } from './UI/users/modal/user-modal.component';
import { Page404Component } from './UI/page404/page404.component';

// VILLAART
import { HomeModule } from './VILLAART/home/home.module';
import { RoomsModule } from './VILLAART/rooms/rooms.module';
import { CaresModule } from './VILLAART/cares/cares.module';
import { SpacesModule } from './VILLAART/spaces/spaces.module';
import { InvitationModule } from './VILLAART/invitation/invitation.module';


@NgModule({
  declarations: [
    AppComponent,
    // UI
    NavigationComponent,
    LoginComponent,
    RegisterComponent,
    ProfileComponent,
    UsersAdminComponent,
    UserModalComponent,
    Page404Component
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    // Routing
    routing,
    // Firebase
    AngularFireModule.initializeApp(environment.firebaseConfig, 'Agama2'),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    // Material
    MaterialModule,
    // WEB
    HomeModule,
    InvitationModule,
    RoomsModule,
    CaresModule,
    SpacesModule
  ],
  providers: [ AngularFireAuth, AngularFirestore ],
  bootstrap: [AppComponent],
  entryComponents: [UserModalComponent]
})
export class AppModule {}
