import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { EventsAdminComponent } from './admin/events-admin.component';
import { EventDetailsComponent } from './details/event-details.component';
import { AdminGuard } from 'src/app/UI/guard/admin.guard';

export const routes: Routes = [
  { path: 'event/:id', component: EventDetailsComponent },
  { path: 'admin/events', component: EventsAdminComponent, canActivate: [AdminGuard] },
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
