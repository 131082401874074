import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/UI/material/material.module';

import { routing } from './cares.routing';
import { CaresComponent } from './cares.component';
import { CaresListComponent } from './services/list/cares-list.component';
import { CareModalComponent } from './services/modal/care-modal.component';
import { CaresAdminComponent } from './services/admin/cares-admin.component';
import { PictureModalComponent } from './pictures/modal/picture-modal.component';
import { PicturesAdminComponent } from './pictures/admin/pictures-admin.component';
import { PicturesListComponent } from './pictures/list/pictures-list.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    routing
  ],
  declarations: [
    CaresComponent,
    CaresListComponent,
    CareModalComponent,
    CaresAdminComponent,
    PicturesAdminComponent,
    PictureModalComponent,
    PicturesListComponent
  ],
  exports: [],
  entryComponents: [
    CareModalComponent,
    PictureModalComponent
  ]
})
export class CaresModule { }
