import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { SpaceInterface } from '../models/space';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpacesApiService {
  constructor(private afs: AngularFirestore, private storage: AngularFireStorage) {}

  private booksCollection: AngularFirestoreCollection<SpaceInterface>;
  private spaces: Observable<SpaceInterface[]>;
  private bookDoc: AngularFirestoreDocument<SpaceInterface>;
  private space: Observable<SpaceInterface>;
  public selectedSpace: SpaceInterface = {
    id: null
  };

  unSelectedSpace() {
    this.selectedSpace = {};
  }

  getAllSpaces() {
    this.booksCollection = this.afs.collection<SpaceInterface>('spaces', ref => ref
    .orderBy('order'));
    return this.spaces = this.booksCollection.snapshotChanges()
    .pipe(map( changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as SpaceInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  addSpace(space: SpaceInterface): void {
    this.booksCollection.add(space);
  }
  updateSpace(space: SpaceInterface): void {
    const idSpace = space.id;
    this.bookDoc = this.afs.doc<SpaceInterface>(`spaces/${idSpace}`);
    this.bookDoc.update(space);
  }
  deleteSpace(idSpace: string): void {
    this.bookDoc = this.afs.doc<SpaceInterface>(`spaces/${idSpace}`);
    this.bookDoc.delete();
  }
}
