import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { RoomsComponent } from './rooms.component';
import { RoomsAdminComponent } from './items/admin/rooms-admin.component';
import { AdminGuard } from 'src/app/UI/guard/admin.guard';
import { PicturesAdminComponent } from './pictures/admin/pictures-admin.component';

export const routes: Routes = [
  { path: 'rooms', component: RoomsComponent },
  { path: 'admin/rooms', component: RoomsAdminComponent, canActivate: [AdminGuard] },
  { path: 'admin/roomspictures', component: PicturesAdminComponent, canActivate: [AdminGuard] },
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
