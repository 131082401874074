import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CaresApiService } from '../services/cares-api.service';
import { CareInterface } from '../models/care';
import { careType } from 'src/app/VILLAART/shared/careType';


@Component({
  selector: 'app-care-modal',
  templateUrl: './care-modal.component.html',
  styleUrls: ['./care-modal.component.css']
})
export class CareModalComponent {
  public careType = careType;

  constructor(
    public dialogRef: MatDialogRef<CareModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CareInterface,
    public dataApi: CaresApiService
  ) {}

  onNoClick(): void {
    this.dataApi.unSelectedCare();
    this.dialogRef.close();
  }

  onSaveCare(): void {
    if (this.dataApi.selectedCare.id) {
      this.dataApi.selectedCare.userUid = this.data.userUid;
      this.dataApi.updateCare(this.dataApi.selectedCare); // Update Care
    } else {
      this.dataApi.selectedCare.userUid = this.data.userUid;
      this.dataApi.addCare(this.dataApi.selectedCare); // New Care
    }
    this.dataApi.unSelectedCare();
  }

  onDeleteCare(): void {
    const confirmacion = confirm('¿Estás seguro?');
    if (confirmacion) {
      this.dataApi.deleteCare(this.dataApi.selectedCare.id);
    }
  }
}
