import { Component, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EventsApiService } from '../services/events-api.service';
import { EventInterface } from '../models/event';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-event-modal',
  templateUrl: './event-modal.component.html',
  styleUrls: ['./event-modal.component.css']
})
export class EventModalComponent {
  uploadPercent: Observable<number>;
  urlPdf: Observable<string>;
  @ViewChild('pdfUpload') inputPdfUpload: ElementRef;

  sectors: string[] = [
    'Administración y Empresa pública',
    'Alimentación y Hostelería',
    'Asociación y Varios',
    'Consultoría',
    'Comercio y Distribución',
    'Educación',
    'Energía',
    'Farmacia',
    'Financiero y Seguros',
    'Industria y Transporte',
    'Juegos y Ocio',
    'Moda',
    'Salud',
    'Servicios',
    'Tecnología y Comunicación'
  ];

  constructor(
    public dialogRef: MatDialogRef<EventModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EventInterface,
    public dataApi: EventsApiService,
    private storage: AngularFireStorage
  ) {}

  onNoClick(): void {
    this.dataApi.unSelectedPublication();
    this.dialogRef.close();
  }

  onUpload(e): void {
    let id: string;
    if (this.dataApi.selectedPublication.pdfId) {
      id = this.dataApi.selectedPublication.pdfId;
    } else {
      id = Math.random()
        .toString(36)
        .substring(2);
      this.dataApi.selectedPublication.pdfId = id;
    }
    const file = e.target.files[0];
    const filePath = `events/event_${id}.jpg`;
    const ref = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    task
      .snapshotChanges()
      .pipe(finalize(() => (this.urlPdf = ref.getDownloadURL())))
      .subscribe();
  }

  onSavePublication(): void {
    if (this.dataApi.selectedPublication.id) {
      this.dataApi.selectedPublication.userUid = this.data.userUid;
      this.dataApi.selectedPublication.urlPdf = this.inputPdfUpload.nativeElement.value;
      this.dataApi.updateClient(this.dataApi.selectedPublication); // Update Publication
    } else {
      this.dataApi.selectedPublication.userUid = this.data.userUid;
      this.dataApi.selectedPublication.urlPdf = this.inputPdfUpload.nativeElement.value;
      this.dataApi.addClient(this.dataApi.selectedPublication); // New Publication
    }
    this.dataApi.unSelectedPublication();
  }

  onDeletePublication(): void {
    const confirmacion = confirm('Are you sure?');
    if (confirmacion) {
      this.dataApi.onUploadDelete(this.dataApi.selectedPublication.pdfId);
      this.dataApi.deletePublication(this.dataApi.selectedPublication.id);
    }
  }
}
