import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { PictureInterface } from '../models/picture';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PicturesApiService {
  constructor(private afs: AngularFirestore, private storage: AngularFireStorage) {}

  private booksCollection: AngularFirestoreCollection<PictureInterface>;
  private bookDoc: AngularFirestoreDocument<PictureInterface>;
  private pictures: Observable<PictureInterface[]>;
  private picture: Observable<PictureInterface>;
  public selectedPicture: PictureInterface = {
    id: null
  };

  unSelectedPicture() {
    this.selectedPicture = {};
  }

  getAllPictures() {
    this.booksCollection = this.afs.collection<PictureInterface>('carespictures', ref => ref
    .orderBy('name'));
    return this.pictures = this.booksCollection.snapshotChanges()
    .pipe(map( changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as PictureInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  getOnePicture(idPicture: string) {
    this.bookDoc = this.afs.doc<PictureInterface>(`carespictures/${idPicture}`);
    return this.picture = this.bookDoc.snapshotChanges().pipe(map(action => {
      if (action.payload.exists === false) {
        return null;
      } else {
        const data = action.payload.data() as PictureInterface;
        data.id = action.payload.id;
        return data;
      }
    }));
  }

  addPicture(picture: PictureInterface): void {
    this.booksCollection.add(picture);
  }
  updatePicture(picture: PictureInterface): void {
    const idPicture = picture.id;
    this.bookDoc = this.afs.doc<PictureInterface>(`carespictures/${idPicture}`);
    this.bookDoc.update(picture);
  }
  deletePicture(idCare: string): void {
    this.bookDoc = this.afs.doc<PictureInterface>(`carespictures/${idCare}`);
    this.bookDoc.delete();
  }

  onUploadDelete(idFile: string) {
    const filePath = `carespictures/picture_${idFile}`;
    const ref = this.storage.ref(filePath);
    ref.delete();
  }
}
