import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/UI/material/material.module';

import { routing } from './spaces.routing';
import { SpacesComponent } from './spaces.component';
import { SpaceModalComponent } from './items/modal/space-modal.component';
import { SpacesAdminComponent } from './items/admin/spaces-admin.component';
import { SpacesListComponent } from './items/list/spaces-list.component';
import { PicturesAdminComponent } from './pictures/admin/pictures-admin.component';
import { PictureModalComponent } from './pictures/modal/picture-modal.component';
import { PicturesListComponent } from './pictures/list/pictures-list.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    routing
  ],
  declarations: [
    SpacesComponent,
    SpaceModalComponent,
    SpacesAdminComponent,
    SpacesListComponent,
    PicturesAdminComponent,
    PictureModalComponent,
    PicturesListComponent
  ],
  exports: [ ],
  entryComponents: [
    SpaceModalComponent,
    PictureModalComponent
  ]
})
export class SpacesModule { }
