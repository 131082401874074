import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { SpacesApiService } from '../services/spaces-api.service';

@Component({
  selector: 'app-spaces-list',
  templateUrl: './spaces-list.component.html',
  styleUrls: ['./spaces-list.component.css']
})
export class SpacesListComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  constructor(private breakpointObserver: BreakpointObserver, private dataApi: SpacesApiService) { }
  public spaces = [];

  ngOnInit() {
    this.getSpaces();
  }

  getSpaces() {
    this.dataApi.getAllSpaces().subscribe(spaces => {
      console.log('SPACES', spaces);
      this.spaces = spaces;
    });
  }

}
