import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { EventsApiService } from '../services/events-api.service';
import { EventInterface } from '../models/event';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css']
})
export class EventDetailsComponent implements OnInit {
  trustedUrl: any;

  constructor(
    private dataApi: EventsApiService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer) { }

  public event: EventInterface = {};

  ngOnInit() {
    const idEvent = this.route.snapshot.params['id'];
    this.getDetails(idEvent);
  }

  getDetails(idEvent: string): void {
    this.dataApi.getOneEvent(idEvent).subscribe(event => {
      this.event = event;
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.event.urlPdf);
    });
  }

}
