import { Component, OnInit, Input } from '@angular/core';
import { PicturesApiService } from '../services/pictures-api.service';

@Component({
  selector: 'app-pictures-list',
  templateUrl: './pictures-list.component.html',
  styleUrls: ['./pictures-list.component.css']
})
export class PicturesListComponent implements OnInit {
  constructor(private dataApi: PicturesApiService) { }
  @Input() space: string;
  public pictures = [];

  ngOnInit() {
    this.getFilteredPictures();
  }

  getFilteredPictures() {
    this.dataApi.getFilteredPictures('space', this.space).subscribe( pictures => {
      console.log('Filtered Pictures', pictures);
      this.pictures = pictures;
    });
  }

}
