export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyCVHIZq3QHQILGtAV9T7y2z8-TimXC9wL0',
    authDomain: 'villaartfire2app.firebaseapp.com',
    databaseURL: 'https://villaartfire2app.firebaseio.com',
    projectId: 'villaartfire2app',
    storageBucket: 'villaartfire2app.appspot.com',
    messagingSenderId: '739661650445'
  }
};
