import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SpaceInterface } from '../models/space';
import { SpacesApiService } from '../services/spaces-api.service';

@Component({
  selector: 'app-space-modal',
  templateUrl: './space-modal.component.html',
  styleUrls: ['./space-modal.component.css']
})
export class SpaceModalComponent {

  constructor(
    public dialogRef: MatDialogRef<SpaceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SpaceInterface,
    public dataApi: SpacesApiService
  ) {}

  onNoClick(): void {
    this.dataApi.unSelectedSpace();
    this.dialogRef.close();
  }

  onSaveSpace(): void {
    if (this.dataApi.selectedSpace.id) {
      this.dataApi.selectedSpace.userUid = this.data.userUid;
      this.dataApi.updateSpace(this.dataApi.selectedSpace); // Update Space
    } else {
      this.dataApi.selectedSpace.userUid = this.data.userUid;
      this.dataApi.addSpace(this.dataApi.selectedSpace); // New Space
    }
    this.dataApi.unSelectedSpace();
  }

  onDeleteSpace(): void {
    const confirmacion = confirm('¿Estás seguro?');
    if (confirmacion) {
      this.dataApi.deleteSpace(this.dataApi.selectedSpace.id);
    }
  }
}
