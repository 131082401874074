import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { take, map, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private afsAuth: AngularFireAuth,
    private router: Router,
    public authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.afsAuth.authState
        .pipe(take(1))
        .pipe(map(authState => !!authState))
        .pipe(tap(auth => {
          if (!auth) {
            this.router.navigate(['/user/login']);
          }
          if (auth) {
            this.authService.isUserAdmin(this.afsAuth.auth.currentUser.uid).subscribe(userRoles => {
                if (!userRoles.roles.admin) {
                  this.router.navigate(['/user/login']);
                }
            });
          }
      }));
  }

}
