import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { EventInterface } from '../models/event';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventsApiService {
  constructor(private afs: AngularFirestore, private storage: AngularFireStorage) {}

  private booksCollection: AngularFirestoreCollection<EventInterface>;
  private publications: Observable<EventInterface[]>;
  private bookDoc: AngularFirestoreDocument<EventInterface>;
  private publication: Observable<EventInterface>;
  public selectedPublication: EventInterface = {
    id: null
  };
  uploadPercent: Observable<number>;
  urlPdf: Observable<string>;
  urlUpload: string;

  unSelectedPublication() {
    this.selectedPublication = {};
  }

  getAllClients() {
    this.booksCollection = this.afs.collection<EventInterface>('events', ref => ref
      .orderBy('inicio'));
    return this.publications = this.booksCollection.snapshotChanges()
    .pipe(map( changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as EventInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  getAllPublicationsOffers() {
    this.booksCollection = this.afs.collection('events', ref => ref.where('oferta', '==', 'Si'));
    return this.publications = this.booksCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as EventInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  getOneEvent(idEvent: string) {
    this.bookDoc = this.afs.doc<EventInterface>(`events/${idEvent}`);
    return this.publication = this.bookDoc.snapshotChanges().pipe(map(action => {
      if (action.payload.exists === false) {
        return null;
      } else {
        const data = action.payload.data() as EventInterface;
        data.id = action.payload.id;
        return data;
      }
    }));
  }

  addClient(publication: EventInterface): void {
    this.booksCollection.add(publication);
  }
  updateClient(publication: EventInterface): void {
    const idPublication = publication.id;
    this.bookDoc = this.afs.doc<EventInterface>(`events/${idPublication}`);
    this.bookDoc.update(publication);
  }
  deletePublication(idPublication: string): void {
    this.bookDoc = this.afs.doc<EventInterface>(`events/${idPublication}`);
    this.bookDoc.delete();
  }

  onUploadDelete(idFile: string) {
    const filePath = `events/event_${idFile}`;
    const ref = this.storage.ref(filePath);
    ref.delete();
  }
}
