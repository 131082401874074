import { Component, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UsersApiService } from '../services/users-api.service';
import { UserInterface } from '../models/user';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.css']
})
export class UserModalComponent {
  uploadPercent: Observable<number>;
  urlPdf: Observable<string>;
  @ViewChild('pdfUpload') inputPdfUpload: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<UserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserInterface,
    public dataApi: UsersApiService,
    private storage: AngularFireStorage
  ) {}

  onNoClick(): void {
    this.dataApi.unSelectedUser();
    this.dialogRef.close();
  }

  onUpload(e): void {
    let id: string;
    if (this.dataApi.selectedUser.pdfId) {
      id = this.dataApi.selectedUser.pdfId;
    } else {
      id = Math.random()
        .toString(36)
        .substring(2);
      this.dataApi.selectedUser.pdfId = id;
    }
    const file = e.target.files[0];
    const filePath = `events/event_${id}.jpg`;
    const ref = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    task
      .snapshotChanges()
      .pipe(finalize(() => (this.urlPdf = ref.getDownloadURL())))
      .subscribe();
  }

  onSaveUser(): void {
    if (this.dataApi.selectedUser.id) {
      this.dataApi.selectedUser.userUid = this.data.userUid;
      this.dataApi.selectedUser.urlPdf = this.inputPdfUpload.nativeElement.value;
      this.dataApi.updateUser(this.dataApi.selectedUser); // Update User
    } else {
      this.dataApi.selectedUser.userUid = this.data.userUid;
      this.dataApi.selectedUser.urlPdf = this.inputPdfUpload.nativeElement.value;
      this.dataApi.addUser(this.dataApi.selectedUser); // New User
    }
    this.dataApi.unSelectedUser();
  }

  onDeleteUser(): void {
    const confirmacion = confirm('Are you sure?');
    if (confirmacion) {
      this.dataApi.onUploadDelete(this.dataApi.selectedUser.pdfId);
      this.dataApi.deleteUser(this.dataApi.selectedUser.id);
    }
  }
}
