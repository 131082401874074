import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/UI/material/material.module';

import { routing } from './invitation.routing';
import { InvitationComponent } from './invitation.component';
import { EventsModule } from './events/events.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    routing,
    EventsModule,
  ],
  declarations: [
    InvitationComponent,
  ],
  exports: [],
  entryComponents: []
})
export class InvitationModule { }
