import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { PictureInterface } from "../models/picture";
import { finalize } from "rxjs/operators";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RoomInterface } from "../../items/models/room";

@Injectable({
  providedIn: "root"
})
export class PicturesApiService {
  constructor(
    private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) {}

  private booksCollection: AngularFirestoreCollection<PictureInterface>;
  private bookDoc: AngularFirestoreDocument<PictureInterface>;
  private pictures: Observable<PictureInterface[]>;
  private picture: Observable<PictureInterface>;
  public selectedPicture: PictureInterface = {
    id: null
  };

  private rooms: Observable<RoomInterface[]>;

  unSelectedPicture() {
    this.selectedPicture = {};
  }

  getAllPictures() {
    this.booksCollection = this.afs.collection<PictureInterface>(
      "roomspictures",
      ref => ref.orderBy("name")
    );
    return (this.pictures = this.booksCollection.snapshotChanges().pipe(
      map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as PictureInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      })
    ));
  }

  getFilteredPictures(filter: string, room: string) {
    this.booksCollection = this.afs.collection("roomspictures", ref =>
      ref.where(filter, "==", room)
    );
    return (this.pictures = this.booksCollection.snapshotChanges().pipe(
      map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as PictureInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      })
    ));
  }

  getOnePicture(idPicture: string) {
    this.bookDoc = this.afs.doc<PictureInterface>(`roomspictures/${idPicture}`);
    return (this.picture = this.bookDoc.snapshotChanges().pipe(
      map(action => {
        if (action.payload.exists === false) {
          return null;
        } else {
          const data = action.payload.data() as PictureInterface;
          data.id = action.payload.id;
          return data;
        }
      })
    ));
  }

  addPicture(picture: PictureInterface): void {
    this.booksCollection.add(picture);
  }
  updatePicture(picture: PictureInterface): void {
    const idPicture = picture.id;
    this.bookDoc = this.afs.doc<PictureInterface>(`roomspictures/${idPicture}`);
    this.bookDoc.update(picture);
  }
  deletePicture(idCare: string): void {
    this.bookDoc = this.afs.doc<PictureInterface>(`roomspictures/${idCare}`);
    this.bookDoc.delete();
  }

  onUploadDelete(idFile: string) {
    const filePath = `roomspictures/picture_${idFile}`;
    const ref = this.storage.ref(filePath);
    ref.delete();
  }

  /* Rooms */
  getAllRooms() {
    return (this.rooms = this.afs
      .collection<RoomInterface>('rooms', ref => ref.orderBy('order'))
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(action => {
            const data = action.payload.doc.data() as RoomInterface;
            data.id = action.payload.doc.id;
            return data;
          });
        })
      ));
  }
}
