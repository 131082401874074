import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

// UI
import { AuthGuard } from './UI/guard/auth.guard';
import { AdminGuard } from './UI/guard/admin.guard';
import { LoginComponent } from './UI/users/login/login.component';
import { RegisterComponent } from './UI/users/register/register.component';
import { ProfileComponent } from './UI/users/profile/profile.component';
import { UsersAdminComponent } from './UI/users/admin/users-admin.component';
import { Page404Component } from './UI/page404/page404.component';
// WEB
import { HomeModule } from './VILLAART/home/home.module';

export const routes: Routes = [
  // Home
  { path: '', component: HomeModule },
  { path: 'home', loadChildren: './VILLAART/home/home.module#HomeModule' },
  // Rooms
  { path: 'rooms', loadChildren: './VILLAART/rooms/rooms.module#RoomsModule'},
  // Cares
  { path: 'cares', loadChildren: './VILLAART/cares/cares.module#CaresModule'},
  { path: 'admin/cares', loadChildren: './VILLAART/cares/cares.module#CaresModule'},
  // Spaces
  { path: 'spaces', loadChildren: './VILLAART/spaces/spaces.module#SpacesModule'},
  // Invitation
  { path: 'invitation', loadChildren: './VILLAART/invitation/invitation.module#InvitationModule'},
  { path: 'admin/events', loadChildren: './VILLAART/invitation/events/events.module#EventsModule'},
  // User
  { path: 'user/login', component: LoginComponent },
  { path: 'user/register', component: RegisterComponent },
  { path: 'user/profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'admin/users', component: UsersAdminComponent, canActivate: [AdminGuard] },
  // Else
  { path: '**', component: Page404Component }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);
