import { Component, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {
  uploadPercent: Observable<number>;
  urlImage: Observable<string>;

  @ViewChild('imageUser') inputImageUser: ElementRef;
  name = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.minLength(4)]);

  hide = true;

  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' :
        this.email.hasError('email') ? 'Not a valid email' :
            '';
  }

  constructor(private router: Router, private authService: AuthService, private storage: AngularFireStorage) {}

  onUpload(e) {
    const id = Math.random().toString(36).substring(2);
    const file = e.target.files[0];
    const filePath = `uploads/profile_${id}.jpg`;
    const ref = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(finalize(() => this.urlImage = ref.getDownloadURL()))
    .subscribe();
  }

  onAddUser() {
    this.authService.registerUser(this.email.value, this.password.value)
    .then((res) => {
      this.authService.isAuth().subscribe(user => {
        if (user) {
          console.log('userActual', user);
          user.updateProfile({
            displayName: this.name.value,
            photoURL: this.inputImageUser.nativeElement.value
          }).then( () => {
            this.onLoginRedirect();
          }).catch((err) => console.log('err', err));
        }
      });
    }).catch(err => console.log('err', err.message));
  }

  onLoginGoogle(): void {
    this.authService.loginGoogleUser()
    .then( (res) => {
      this.onLoginRedirect();
    }).catch(err => console.log('err', err.message));
  }

  onLoginFacebook(): void {
    this.authService.loginFacebookUser()
    .then( (res) => {
      this.onLoginRedirect();
    }).catch(err => console.log('err', err.message));
  }

  onLogout() {
    this.authService.logoutUser();
  }

  onLoginRedirect(): void {
    this.router.navigate(['user/profile']);
  }

}
